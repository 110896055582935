import React, { useEffect } from "react";
import styled from "styled-components";
import { ReactComponent as NotFoundSvg } from "../../assets/media/svg/404-Error-pana.svg";
import { Link } from "react-router-dom";
import { titleHTML } from "../../helpers/title.helper";

const NotFound = () => {
    titleHTML("Eseade");

    // EFFECT
    useEffect(() => {
        const id = localStorage.getItem("src_id");
        const type = localStorage.getItem("src_type");
        if (id || type) {
            localStorage.removeItem("src_id");
            localStorage.removeItem("src_type");
        }
    }, []);

    /** RETURN ----------------------------------------------------------------------- */
    return (
        <MainContainer>
            <MessageContainer>
                <NotFoundSvg width="50%" />
                <Text>
                    Lo sentimos, la página que buscabas no fue encontrada
                </Text>
                <ButtonWrapper>
                    <CustomLink to={"/campus"}>Volver al inicio</CustomLink>
                </ButtonWrapper>
            </MessageContainer>
        </MainContainer>
    );
};

export default NotFound;

const MainContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: space-around;
    height: 85vh;
`;

const MessageContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const ButtonWrapper = styled.div`
    display: flex;
    flex-direction: row;
`;

const Text = styled.div`
    font-size: 1.2rem;
    padding: 1rem;
`;

const CustomLink = styled(Link)`
    background-color: #4E84F9;
    color: #ffffff !important;
    border-radius: 5px;
    padding: 0.5rem 1rem;
    font-weight: 700;
    display: flex;
    align-items: center;
    justify-content: center;

    :hover {
        background-color: #921009;
        color: #ffffff;
    }

    @media screen and (max-width: 768px) {
        padding: 1rem 1.2rem;
        font-size: 1.2rem;
        margin: 1rem;
    }
`;
