import React from "react";
import styled from "styled-components";
import MainCampus from "../../components/templates/campus/MainCampus";
import { titleHTML } from "../../helpers/title.helper";

const Campus = () => {
    titleHTML("Campus | ESEADE");

    return (
        <>
            <HomeWrapper>
                <MainCampus />
            </HomeWrapper>
        </>
    );
};

export default Campus;

const HomeWrapper = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    gap: 15px;
    @media (max-width: 768px) {
        display: flex;
        flex-direction: column;
    }
`;
