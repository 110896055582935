import React, { useState } from "react";
import styled from "styled-components";

// import Navegation from "../navegation/Navegation";
import Copyright from "../../common/Copyright";
import Navegation from "../navegation/Navegation";

const Layout = (props) => {
    // STATE
    const [menu, setMenu] = useState(false);

    // FUNCTIONS
    function toggleMenu() {
        setMenu(!menu);
    }

    function closeMenu() {
        setMenu(false);
    }

    // RETURN
    return (
        <>
            <NavCointainer>
                <Navegation
                    menu={menu}
                    closeMenu={closeMenu}
                    toggleMenu={toggleMenu}
                />
            </NavCointainer>

            <Main menu={menu}>
                {props.children}
                <Copyright></Copyright>
            </Main>
        </>
    );
};

export default Layout;

const NavCointainer = styled.div`
    position: fixed;
    z-index: 999;
    top: 0;
    height: 100vh;
    top: 0;
`;

const Main = styled.main`
    padding: ${(props) =>
        props.bannerExists
            ? "calc(2rem + 140px) 2rem 2rem calc(2rem + 70px)"
            : "calc(2rem + 70px) 2rem 2rem calc(2rem + 70px)"};
    overflow: ${(props) => props.menu && "hidden"};
    height: auto;
    background-color: #f9f9f9;
    @media (max-width: 1440px) {
        padding: ${(props) =>
            props.bannerExists
                ? "calc(2rem + 140px) 1rem 2rem calc(1rem + 70px)"
                : "calc(2rem + 70px) 4rem 2rem calc(4rem + 70px)"};
    }

    @media (max-width: 768px) {
        padding: ${(props) =>
            props.bannerExists
                ? "calc(2rem + 180px) 1rem 2rem 1rem"
                : "calc(2rem + 70px) 1rem 2rem 1rem"};
    }
`;
