import { useEffect, useState } from "react";
import { getMicroLearning } from "../../redux/api/microlearnings";

const useMicrolearning = (id) => {
    const [microLearnings, setMicroLearnings] = useState(null);
    const [currentMicro, setCurrentMicro] = useState(null);

    useEffect(() => {
        microLearnings === null && getMicrolearnings();
    }, [microLearnings]);

    useEffect(() => {
        microLearnings !== null &&
            id &&
            setCurrentMicro(
                microLearnings.filter(
                    (values) => values.repo_id === parseInt(id)
                )
            );
    }, [microLearnings, id]);

    const getMicrolearnings = async () => {
        try {
            const req = await getMicroLearning();

            setMicroLearnings(req.response_data);
        } catch (error) {
            setMicroLearnings(false);
        }
    };

    return { microLearnings, currentMicro };
};

export default useMicrolearning;
