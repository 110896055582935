import React from "react";
import styled from "styled-components";
import LoginForm from "../../molecules/auth/LoginForm";

const AuthComponent = () => {
    let registration = false;


    return (
        <AuthComponentWrapper>
            <LoginForm registration={registration}/>
        </AuthComponentWrapper>
    );
};

export default AuthComponent;

const AuthComponentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    /* padding: 0rem 1rem 0 1rem; */
`;
