import React from "react";
import DefaultNavegation from "../../components/common/DefaultNavegation";
import styled from "styled-components";
import { useParams } from "react-router-dom";
import SimpleLoading from "../../components/common/SimpleLoading";
import useMicrolearning from "../../hooks/microlearning/useGetMicrolearning";
import AsideMicrolearnings from "./AsideMicrolearnings";
import VideoMicrolearning from "./VideoMicrolearning";

const CurrentMicrolearning = () => {
    const { id } = useParams();

    const { microLearnings, currentMicro } = useMicrolearning(id);

    if (microLearnings === null || currentMicro === null) {
        return (
            <Wrapper>
                <SimpleLoading />
            </Wrapper>
        );
    }

    return (
        <Wrapper>
            <DefaultNavegation title={currentMicro[0]?.publication_name} />
            <Grid>
                <VideoContainer>
                    <VideoMicrolearning currentMicro={currentMicro} />
                </VideoContainer>
                <AsideMicrolearnings microLearnings={microLearnings} />
            </Grid>
        </Wrapper>
    );
};

export default CurrentMicrolearning;

const Wrapper = styled.div`
    margin-top: 4rem;
    display: flex;
    justify-content: center;
    width: 100%;
    align-items: center;
    height: 80vh;
`;

const Grid = styled.div`
    display: grid;
    grid-template-columns: 3fr 1fr;
    align-items: center;
    gap: 2rem;
    width: 100%;
`;

const VideoContainer = styled.div`
    width: 100%;
    padding-top: 7rem;
    position: relative;
    border-radius: 20px;
`;
