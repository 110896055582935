import React from "react";
import styled from "styled-components";
import useTheme from "../../hooks/useTheme";

const Copyright = (props) => {
    const { padding } = props;
    const { primary } = useTheme();

    // RETURN
    return (
        <Container padding={padding}>
            <Heading>
                Creado y desarrollado por © ESEADE.
                {new Date().getFullYear()}
            </Heading>
            <SubHeding>
                Contáctanos en{" "}
                <Link color={primary} href="mailto:tecnologia@aden.org">
                    tecnologia@aden.org
                </Link>
            </SubHeding>
        </Container>
    );
};

export default Copyright;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 2rem 2rem 0 2rem;
    row-gap: 0.5rem;
`;

const Heading = styled.div`
    font-size: 1.1rem;
    color: #616161;
`;

const SubHeding = styled.div`
    display: flex;
    column-gap: 0.5rem;
    font-size: 1rem;
    color: #a8a8a8;
`;

const Link = styled.a`
    color: ${(p) => p.color};
`;
