import React from "react";
import CardAsideMicro from "./CardAsideMicro";
import styled from "styled-components";

const AsideMicrolearnings = (props) => {
    const { microLearnings } = props;

    return (
        <Wrapper>
            <Header>También puede interesarte...</Header>
            <Scroll>
                {microLearnings.map((values, index) => (
                    <CardAsideMicro values={values} key={index} />
                ))}
            </Scroll>
        </Wrapper>
    );
};

export default AsideMicrolearnings;

const Wrapper = styled.div`
    width: calc(100% - 3rem);
    box-shadow: 0 8px 24px -4px #18274b14, 0 6px 12px -6px #18274b1f;
    padding: 1.5rem;
    height: 60vh;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    border-radius: 20px;
    background-color: #fff;
`;

const Header = styled.h2`
    width: 100%;
    font-size: 1rem;
`;

const Scroll = styled.div`
    display: flex;
    flex-direction: column;
    overflow: auto;
    gap: 2rem;
`;
