import * as types from "../types";
import persistReducer from "redux-persist/es/persistReducer";
import storage from "redux-persist/lib/storage";
import { deleteAllCookies } from "../../helpers/cookie.helper";

const initialState = {
    user: null,
    loading: false,
    error: null,
    token: null,
    loadingLogin: false,
    // loadingGetUser: false,
};

export const auth = persistReducer(
    {
        storage,
        key: "campus-auth",
        whitelist: ["user", "token"],
    },
    (state = initialState, action) => {
        switch (action.type) {
            // LOGIN USER
            case types.LOGIN_USER_REQUEST:
                return {
                    ...state,
                    loading: true,
                    error: null,
                };
            case types.LOGIN_USER_SUCCESS:
                // localStorage.setItem("token", action.payload.token);
                // localStorage.setItem(
                //     "sis_id",
                //     action.payload.profile_data.sis_id
                // );
                // sessionStorage.clear();
                return {
                    ...state,
                    loading: false,
                    // authToken: action.payload.token,
                    user: {
                        ...action.payload.user,
                    },
                    token: {
                        ...action.payload.token,
                    },
                };
            case types.LOGIN_USER_FAIL:
                localStorage.removeItem("token");
                localStorage.removeItem("sis_id");
                sessionStorage.clear();
                return {
                    ...state,
                    loading: false,
                    error: action.payload,
                };
            case types.CLEAR_ERROR_LOGIN:
                return {
                    ...state,
                    error: null,
                };

            // LOGOUT
            case types.LOGOUT_USER:
                window.location.reload();
                localStorage.clear();
                deleteAllCookies();
                return initialState;

            default:
                return state;
        }
    }
);
