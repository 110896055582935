import React from "react";
import styled from "styled-components";

import { ErrorMessage, Field } from "formik";
import { TextField } from "@mui/material";

const DefaultField = (props) => {
    const { name, type, label, placeholder, variant = 'standard' } = props;

    // RETURN
    return (
        <Field
            as={TextField}
            name={name}
            helperText={<ErrorMessage name={name} component={CustomError} />}
            type={type}
            label={label}
            variant={variant}
            placeholder={placeholder}
            color="primary"
            size="small"
            fullWidth
            {...props}
        />
    );
};

export default DefaultField;

const CustomError = styled.span`
    font-size: 0.75rem;
    font-weight: 700;
    color: #4E84F9;
`;
