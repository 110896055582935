import React from "react";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";

import MaterialThemeProvider from "./assets/themes/MaterialThemeProvider";
// import { LayoutSplashScreen } from './components/common/SplashScreen';
import { StyledEngineProvider } from "@mui/material";
import ThemeProvider from "./provider/ThemeProvider";
import SnackbarProvider from "react-simple-snackbar";
import Routes from "./routes";

export default function App({ store, persistor, basename }) {
    return (
        /* Provide Redux store */
        <SnackbarProvider>
            <Provider store={store}>
                {/* Asynchronously persist redux stores and show `SplashScreen` while it's loading. */}
                <PersistGate persistor={persistor}>
                    {/* Override `basename` (e.g: `homepage` in `package.json`) */}
                    <BrowserRouter basename={basename}>
                        {/* Tema por In Company */}
                        <StyledEngineProvider injectFirst>
                            <ThemeProvider>
                                {/*This library only returns the location that has been active before the recent location change in the current window lifetime.*/}
                                <MaterialThemeProvider>
                                    {/* Render routes with provided `Layout`. */}
                                    <Routes />
                                </MaterialThemeProvider>
                            </ThemeProvider>
                        </StyledEngineProvider>
                    </BrowserRouter>
                </PersistGate>
            </Provider>
        </SnackbarProvider>
    );
}
