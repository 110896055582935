import React from "react";
import styled from "styled-components";
import imageLogo from "../../../assets/media/aden/alumnos-eseade.png";

const PublicityWithout = () => {
    return (
        <Container>
            <Overlay />
            <img src={imageLogo} />
        </Container>
    );
};

export default PublicityWithout;

const Container = styled.div`
    display: flex;
    position: relative;
    flex-direction: column;
    justify-content: center;
    row-gap: 1rem;
    width: 100%;
    height: 100%;
    overflow: hidden;
    color: #ffffff;

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    @media (max-width: 768px) {
        visibility: hidden !important;
        display: none !important;
    }
`;

const Overlay = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background: rgb(78, 132, 249);
    background: linear-gradient(
        65deg,
        rgba(78, 132, 249, 0.3) 0%,
        rgba(78, 132, 249, 0.4) 50%,
        rgba(78, 132, 249, 0.6) 100%
    );
`;
