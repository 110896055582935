import React from "react";
import styled from "styled-components";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

const BannerHome = () => {
    return (
        <Wrapper
            onClick={() => {
                window.open("https://www.eseade.edu.ar/");
            }}
        >
            <Paper></Paper>
            <TextWrapper>
                <h2>ESEADE libre como vos</h2>
                <p>Contribuyendo a la difusión de proyectos</p>
                <span>
                    Conoce más <ArrowForwardIcon />
                </span>
            </TextWrapper>
        </Wrapper>
    );
};

export default BannerHome;

const Wrapper = styled.div`
    width: 100%;
    position: relative;
    height: 250px;
    border-radius: 20px;
    cursor: pointer;
    box-shadow: 0 8px 24px -4px #18274b14, 0 6px 12px -6px #18274b1f;
    background: url("https://cms.adenu.edu.pa/uploads/PNG_Banner_Aden_Te_Acompana_05f25372cc.png");
    background-position: center center;
    background-size: cover;
    z-index: 1;
    overflow: hidden;

    @media (max-width: 1000px) {
        height: 300px;
    }
`;

const Paper = styled.div`
    position: absolute;
    content: "";
    background-color: #fff;
    z-index: 2;
    right: -1px;
    transform: skewY(-6deg);
    top: 40%;
    bottom: 0;
    width: 80%;
    padding-right: 1.5rem;
    display: flex;
    height: 699px;
    justify-content: center;
    gap: 1rem;
    flex-direction: column;
    align-items: end;
    border-top-left-radius: 40px;
    h2 {
        margin-bottom: 35%;
        transform: skewY(6deg);
        color: black;
        font-size: 2.5rem;
    }
`;

const TextWrapper = styled.div`
    position: absolute;
    z-index: 3;
    bottom: 20px;
    right: 20px;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    justify-content: center;
    align-items: flex-end;
    h2 {
        font-size: 2rem;
        background: #4e84f9;
        font-weight: 900;
        color: #fff;
    }

    span {
        display: flex;
        align-item: center;
        color: #4e84f9;
        gap: 0.3rem;
        svg {
            font-size: 1.2rem;
        }
    }
`;
