import React from "react";
import styled from "styled-components";
import { useHistory } from "react-router-dom";

const CardMicrolearning = (props) => {
    const { values } = props;

    const history = useHistory();

    const handleClick = () => {
        history.push(`./microlearnings/${values?.repo_id}`);
    };

    return (
        <Card onClick={handleClick}>
            <Header>
                <img src={values?.image_url} alt="imagen video" />
            </Header>
            <Body>
                <Tags>
                    {values?.tags.slice(0, 2).map((tag, index) => (
                        <p key={index}>#{tag}</p>
                    ))}
                </Tags>
                <Title>
                    <h2>{values?.publication_name}</h2>
                </Title>
                <Authors>
                    <p>Por</p>
                    <span>{values?.acropolis_author}</span>
                </Authors>
            </Body>
        </Card>
    );
};

export default CardMicrolearning;

const Card = styled.div`
    width: 100%;
    max-width: 300px;
    border: solid 1px #e1e1e1;
    border-radius: 20px;
    cursor: pointer;
`;

const Header = styled.div`
    height: 120px;
    overflow: hidden;
    border-radius: 20px;

    img {
        width: 100%;
        object-fit: contain;
    }
`;

const Body = styled.div`
    display: flex;
    flex-direction: column;
    width: calc(100% - 2rem);
    padding: 1rem;
    gap: 1rem;
`;

const Tags = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    gap: 0.3rem;
    justify-content: flex-start;

    height: 1rem;

    p {
        font-size: 0.68rem;
        color: #a8a8a8;
    }
`;

const Title = styled.div`
    width: 96%;
    height: 3rem;
    h2 {
        text-overflow: ellipsis;
        overflow: hidden;
        display: -webkit-box !important;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        white-space: normal;
        font-size: 0.9rem;
    }
`;

const Authors = styled.div`
    display: flex;
    gap: 0.3rem;

    font-size: 0.85rem;

    p {
        color: #000;
    }

    span {
        color: #4e84f9;
        font-weight: bold;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
    }
`;
