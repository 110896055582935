import React from "react";
import { Redirect, Route } from "react-router-dom";

import { connect } from "react-redux";
import { useQueryParams } from "../hooks/useQueryParams";

const PublicRoute = (props) => {
    const {
        layout = "common",
        rol,
        isAuthenticated,
        blockRedirect = false,
        component: Component,
        ...rest
    } = props;

    const queryTab = useQueryParams();

    const renderLayout = (layout, Component) => {
        const cases = {
            common: Component,
        };

        return cases["common"];
    };

    const handleRedirect = () => {
        let rolFun = rol?.toLowerCase()
        const redirect = queryTab.get("redirect")
        if (!!redirect) {
            return redirect
        }
        if (rolFun === "docente") {
            return ("/profesor/dashboard");
        }
        if (rolFun === "alumno" || rolFun === "visita" || rolFun === "ondemand" || rolFun === 'admin') {
            return ("/campus");
        }
        if (rolFun === "academico") {
            return ("/academico");
        }
        else{
            return ('/campus')
        }
    };

    return (
        <>
            <Route
                {...rest}
                render={(props) =>
                    isAuthenticated && !blockRedirect ? (
                        <Redirect
                            to={handleRedirect() === "not-found" ? "/" : handleRedirect()}
                        />
                    ) : (
                        renderLayout(layout, <Component {...props} />)
                    )
                }
            />
        </>
    );
};

const mapStateToProps = (state) => ({
    isAuthenticated: !!state.auth.user,
});

export default connect(mapStateToProps)(PublicRoute);
