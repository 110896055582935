// Auth
export const LOGIN_USER_REQUEST = 'LOGIN_USER_REQUEST';
export const LOGIN_USER_SUCCESS = 'LOGIN_USER_SUCCESS';
export const LOGIN_USER_FAIL = 'LOGIN_USER_FAIL';

export const CLEAR_ERROR_LOGIN = 'CLEAR_ERROR_LOGIN';

export const LOGOUT_USER = 'LOGOUT_USER';

// REFRESH TOKEN
export const SET_REFRESH_TOKEN = 'SET_REFRESH_TOKEN';
