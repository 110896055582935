import { Button, IconButton } from "@mui/material";
import React, { useRef } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { ChevronLeftRounded, ChevronRightRounded } from "@mui/icons-material";
import Carousel from "react-elastic-carousel";
import { Skeleton } from "@mui/material";
import NoResultsFound from "./NoResultsFound";

const breakPoints = [
    { width: 1, itemsToShow: 1 },
    { width: 550, itemsToShow: 2 },
    { width: 768, itemsToShow: 3 },
    { width: 1280, itemsToShow: 5 },
];

const CustomSlider = (props) => {
    const {
        array = false,
        card: Card,
        to,
        title,
        buttonTitle = "Ver todos",
    } = props;

    // HOOKS
    const primary = "#4e84f9";
    const carouselRef = useRef(null);

    // FUNCTIONS
    const handleNextArrow = () => {
        carouselRef.current.slideNext();
    };

    const handlePrevArrow = () => {
        carouselRef.current.slidePrev();
    };

    if (array === false) {
        return (
            <NoResultsFound
                message={"No hay resultados en: " + title.toUpperCase()}
            />
        );
    }

    return (
        <SliderCampusContainer>
            {/* Header */}
            <OptionsWrapper>
                <Title>{title}</Title>
                <ArrowsWrapper>
                    <FakeLink to={to}>
                        <Button color="primary" size="small">
                            {buttonTitle}
                        </Button>
                    </FakeLink>
                    <FakeIconButton
                        size="small"
                        color={primary}
                        onClick={handlePrevArrow}
                    >
                        <ChevronLeftRounded />
                    </FakeIconButton>
                    <FakeIconButton
                        size="small"
                        color={primary}
                        onClick={handleNextArrow}
                    >
                        <ChevronRightRounded />
                    </FakeIconButton>
                </ArrowsWrapper>
            </OptionsWrapper>
            {/* Body */}
            {!!array ? (
                <FakeCarousel
                    length={array.length}
                    breakPoints={breakPoints}
                    ref={carouselRef}
                    itemPadding={[8]}
                >
                    {array
                        .random()
                        .slice(0, 10)
                        .map((item, index) => (
                            <Card values={item} key={index} index={index} />
                        ))}
                </FakeCarousel>
            ) : (
                <FakeCarousel
                    length={4}
                    breakPoints={breakPoints}
                    ref={carouselRef}
                    itemPadding={[8]}
                >
                    {[1, 1, 1, 1].map(() => (
                        <SkeletonFake
                            sx={{ bgcolor: "grey.900" }}
                            variant="rectangular"
                            width={"100%"}
                            height={250}
                        />
                    ))}
                </FakeCarousel>
            )}
        </SliderCampusContainer>
    );
};

export default CustomSlider;

const SliderCampusContainer = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 2rem;
`;

const Title = styled.div`
    color: #4e84f9;
    font-weight: bold;
`;

const OptionsWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const ArrowsWrapper = styled.div`
    display: flex;
    column-gap: 1rem;
`;

const FakeLink = styled(Link)`
    display: inline-flex;
    @media (max-width: 768px) {
        display: none;
    }
`;

const FakeIconButton = styled(IconButton)`
    background-color: ${(p) => p.color};
    color: #ffffff;
    border-radius: 5px;
    transition: all 0.3s ease-in-out;
    :hover {
        background-color: ${(p) => p.color};
        filter: brightness(1.2);
    }
`;

const FakeCarousel = styled(Carousel)`
    .rec.rec-arrow {
        display: none;
        visibility: hidden;
    }

    .rec.rec-pagination {
        display: none;
        visibility: hidden;
    }
`;

const SkeletonFake = styled(Skeleton)`
    border-radius: 20px;
`;
