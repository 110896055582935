import React from "react";
import styled from "styled-components";
import { useHistory } from "react-router-dom";

const CardAsideMicro = (props) => {
    const { values } = props;

    const history = useHistory();

    return (
        <Wrapper
            onClick={() => {
                history.push(`../microlearnings/${values?.repo_id}`);
            }}
        >
            <Image>
                <img src={values?.image_url} alt="imagen de video" />
            </Image>
            <Body>
                <Title>{values?.publication_name}</Title>
                <Author>Por {values?.acropolis_author}</Author>
            </Body>
        </Wrapper>
    );
};

export default CardAsideMicro;

const Wrapper = styled.div`
    display: grid;
    grid-template-columns: 2fr 3fr;
    gap: 1rem;
    width: 100%;
    align-items: center;
    transition: all 0.3s ease-in-out;
    cursor: pointer;

    :hover {
        background-color: #f1f1f1;
    }
`;
const Image = styled.div`
    width: 100%;

    img {
        width: 100%;
        object-fit: cover;
    }
`;
const Body = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.3rem;
    justify-content: flex-start;
`;
const Title = styled.h2`
    font-size: 0.85rem;
    color: #4e84f9;
    text-overflow: ellipsis;
    overflow: hidden;
    // Addition lines for 2 line or multiline ellipsis
    display: -webkit-box !important;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    white-space: normal;
    width: 98%;
`;
const Author = styled.p`
    font-size: 0.75rem;
    width: 98%;
    color: #d1d1d1;
`;
