import React, { createContext, useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';

export const ThemeContext = createContext();

let palette = {
    primary: '#4E84F9',
    secondary: '#ffffff'
};

const ThemeProvider = (props) => {
    const { auth, ondemand, children } = props;

    const [theme, setTheme] = useState(palette);

    useEffect(() => {
        if (auth && !!ondemand) {
            setTheme({
                primary: ondemand?.primary_color || '#4E84F9',
                secondary: ondemand?.secondary_color || '#ffffff'
            });
        }
        else{
            setTheme({
                primary: '#4E84F9',
                secondary: '#ffffff'
            });
        }
    }, [auth, ondemand]);

    const contextValue = useMemo(() => {
        return {
            values: theme,
            deleteTheme: () => {
                setTheme(palette);
            }
        };
    }, [theme]);

    return (
        <ThemeContext.Provider value={contextValue}>
            {children}
        </ThemeContext.Provider>
    );
};

const mapStateToProps = (state) => ({
    auth: !!state.auth.authToken,
    ondemand: state?.ondemand?.ondemand
});

export default connect(mapStateToProps)(ThemeProvider);
