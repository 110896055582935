import React from "react";
import styled from "styled-components";
import CardMicrolearning from "../ui/organisms/microlearning/CardMicrolearning";
import CustomSlider from "../common/CustomSlider";
import { Card } from "@mui/material";
import useMicrolearning from "../../hooks/microlearning/useGetMicrolearning";
// import CardHome from "../ui/organisms/home/CardHome";

const HomeGrid = () => {
    const { microLearnings } = useMicrolearning();

    return (
        <Wrapper>
            <CardFake>
                <CustomSlider
                    array={microLearnings}
                    card={CardMicrolearning}
                    to="./microlearnings"
                    title="Biblioteca de contenidos"
                    buttonTitle="Ver todos"
                />
            </CardFake>
        </Wrapper>
    );
};

export default HomeGrid;

const Wrapper = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
    gap: 1.5rem;
    width: 100%;
    box-sizing: border-box;
`;

const CardFake = styled(Card)`
    width: calc(100% - 48px);
    box-shadow: 0 8px 24px -4px #18274b14, 0 6px 12px -6px #18274b1f;
    border-radius: 20px;
    background-color: #fff;
    padding: 32px 24px;
    height: min-content;
    /* min-height: 500px; */
`;
