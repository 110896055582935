import React from 'react';
import styled from 'styled-components';

import { Typography } from '@mui/material';

const Text = (props) => {
    const { fontSize, fontWeight, textTransform, textColor } = props;

    // RETURN
    return (
        <FakeTypography
            fontSize={fontSize}
            fontWeight={fontWeight}
            textTransform={textTransform}
            textColor={textColor}
            {...props}
        >
            {props.children}
        </FakeTypography>
    );
};

export default Text;

const FakeTypography = styled(Typography)`
    margin: 0;
    line-height: normal;
    font-size: ${(p) => p.fontSize};
    font-weight: ${(p) => p.fontWeight};
    text-transform: ${(p) => p.textTransform};
    color: ${(p) => p.textColor};
`;
