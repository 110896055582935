import "./index.css";
import "react-phone-input-2/lib/material.css";
import React from "react";
import ReactDOM from "react-dom/client";
import axios from "axios";
import * as _redux from "./redux";
import store, { persistor } from "./redux/store";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

import { polyfill } from "./polyfill/index";

const { PUBLIC_URL } = process.env;
// Limpio los logs
// (process.env.REACT_APP_NAME === 'PRODUCCION') && (console.log = console.warn = console.error = () => {});

// Ejecuto todos los polyfill creados
polyfill();

_redux.setupAxios(axios, store);

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
    <App store={store} persistor={persistor} basename={PUBLIC_URL} />,
);

reportWebVitals();
