import axios from "axios";

export async function loginUser(payload) {
    const URL = `${process.env.REACT_APP_FA_URL}/api/login`;

    const config = {
        headers: {
            Authorization:
                "6-cpS1aBRbWTE82GrdwAiK-n8bBEZrcporRwIgM15Aw2B9W39iCWrw9K",
            "Content-Type": "application/json",
        },
    };

    const body = {
        applicationId: "d9685f80-6035-4397-84cd-5f828c24a5be",
        loginId: payload.user,
        password: payload.password,
    };

    try {
        const request = await axios.post(URL, body, config);
        return request;
    } catch (error) {
        throw new Error(error);
    }
}
