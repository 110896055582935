import * as yup from 'yup';

export const loginSchema = yup.object().shape({
    user: yup
        .string()
        .email('Formato incorrecto')
        .required('Correo requerido')
        .max(320, 'Máximo 320 caracteres'),
    password: yup
        .string()
        .min(8, 'Mínimo 8 caracteres')
        .max(50, 'Máximo 50 caracteres')
        .required('Contraseña requerida')
});