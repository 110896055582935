import { all } from "redux-saga/effects";
import { combineReducers } from "redux";

// Reducers
import { auth } from "./reducers/auth";

// Sagas
import { authWatcher } from "./sagas/auth";

const appReducer = combineReducers({
    auth: auth,
});

export const rootReducer = (state, action) => {
    if (action.type === "LOGOUT_USER") {
        return appReducer(undefined, action);
    }

    return appReducer(state, action);
};

export function* rootSaga() {
    yield all([authWatcher()]);
}
