import React from "react";
import styled from "styled-components";
import useMicrolearning from "../../hooks/microlearning/useGetMicrolearning";
import { Skeleton } from "@mui/material";
import CardMicrolearning from "../../components/ui/organisms/microlearning/CardMicrolearning";

const Microlearnings = () => {
    const { microLearnings } = useMicrolearning();

    // RETURN
    if (microLearnings === null) {
        return (
            <Container>
                <Header>
                    <Title>Biblioteca de contenidos</Title>
                </Header>
                <Wrapper>
                    {[1, 1, 1, 1].map(() => (
                        <SkeletonFake
                            sx={{ bgcolor: "grey.900" }}
                            variant="rectangular"
                            width={"100%"}
                            height={250}
                        />
                    ))}
                </Wrapper>
            </Container>
        );
    }

    return (
        <Container>
            <Header>
                <Title>Biblioteca de contenidos</Title>
            </Header>
            {microLearnings ? (
                <>
                    {microLearnings.length > 0 ? (
                        <Wrapper>
                            {microLearnings.map((values, index) => (
                                <CardMicrolearning
                                    key={index}
                                    values={values}
                                />
                            ))}
                        </Wrapper>
                    ) : (
                        <Container>
                            <p>No se encontraron contenidos</p>
                        </Container>
                    )}
                </>
            ) : (
                <p>Hubo un error</p>
            )}
        </Container>
    );
};

export default Microlearnings;

const Container = styled.div`
    width: calc(100% - 4rem);
    padding: 2rem;
    height: calc(70vh - 4rem);
    overflow: hidden;
    border-radius: 20px;
    background-color: #fff;
    box-shadow: 0 8px 24px -4px #18274b14, 0 6px 12px -6px #18274b1f;
`;

const Title = styled.h2`
    font-size: 1.2rem;
    color: #4e84f9;
`;

const Wrapper = styled.div`
    width: calc(100% - 4rem);
    padding: 2rem;
    height: 85%;
    overflow: auto;
    display: grid;
    gap: 2rem;
    grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
`;

const Header = styled.div`
    width: 100%;
    display: flex;
`;

const SkeletonFake = styled(Skeleton)`
    border-radius: 20px;
`;
