import React from "react";
import ReactPlayer from "react-player";
import styled from "styled-components";

const VideoMicrolearning = (props) => {
    const { currentMicro } = props;

    const microLearning = currentMicro[0];

    return (
        <VideoWrapper>
            {microLearning.categoria === 45 ? (
                <Iframe src={microLearning.video_url} />
            ) : (
                <ReactPlayerFake
                    url={microLearning.video_url}
                    controls={true}
                    width="100%"
                    height="100%"
                    playsinline
                    // onDuration={(e) => setDurationVideo(e)}
                    config={{
                        file: {
                            attributes: {
                                poster: `${microLearning.image_url}`,
                            },
                        },
                    }}
                    // onProgress={handleFinishVideo}
                />
            )}
        </VideoWrapper>
    );
};

export default VideoMicrolearning;

const VideoWrapper = styled.div`
    position: relative;
    padding-top: 56.25%;
`;

const Iframe = styled.iframe`
    position: absolute;
    top: 0;
    overflow: hidden;
    background-color: red;
    width: 100%;
    height: 100%;
`;

const ReactPlayerFake = styled(ReactPlayer)`
    position: absolute;
    top: 0;
    left: 0;
`;
