import React from "react";
import { Redirect, Route } from "react-router-dom";

import { connect } from "react-redux";
import ChatLayout from "../components/shared/chat-layout/ChatLayout";

const ErrorRoute = (props) => {
    const {
        layout = "common",
        isAuthenticated,
        user,
        component: Component,
        ...rest
    } = props;

    // FUNCTION
    const renderLayout = (layout, Component) => {
        const cases = {
            common: <ChatLayout>{Component}</ChatLayout>,
        };

        return cases[layout] || cases["common"];
    };

    // RETURN
    return (
        <Route
            {...rest}
            component={(props) =>
                isAuthenticated ? (
                    renderLayout(layout, <Component {...props} />)
                ) : (
                    <Redirect to={`/auth`} />
                )
            }
        />
    );
};

const mapStateToProps = (state) => ({
    isAuthenticated: !!state.auth.user,
    user: state.auth.user,
});

export default connect(mapStateToProps)(ErrorRoute);
