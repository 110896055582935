export async function getMicroLearning() {
    const URL = `${process.env.REACT_APP_SISAPI}/v1/acropolis/microcontents`;

    const config = {
        headers: {
            Authorization: "c7121220-c8bf-497a-9f13-427134b184c0",
        },
    };

    try {
        const request = await fetch(URL, config);
        return await request.json();
    } catch (error) {
        throw new Error(error);
    }
}
