import React from "react";
import styled from "styled-components";
import BannerHome from "../../home/BannerHome";
import HomeGrid from "../../home/HomeGrid";

const MainCampus = () => {
    return (
        <GridWrapper>
            <BannerHome />
            <HomeGrid />
        </GridWrapper>
    );

    // RETURN
};

export default MainCampus;

const GridWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 3rem;
`;
