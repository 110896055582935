import React from "react";
import styled from "styled-components";
import PublicityWithout from "../../components/templates/auth/PublicityWithout";
import AuthForm from "../../components/templates/auth/AuthForm";
import { titleHTML } from "../../helpers/title.helper";
// import AuthFormLab from "../../components/templates/auth/AuthFormLab";

const AuthPage = () => {
    titleHTML("Eseade");

    return (
        <>
            <LoginWrapper>
                <AuthForm />
                <PublicityWithout />
            </LoginWrapper>
        </>
    );
};

export default AuthPage;

const LoginWrapper = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    width: 100%;
    height: 100vh;
    overflow: hidden;

    @media screen and (max-width: 768px) {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
`;
