import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

import { MenuRounded } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import Menu from "../menu/Menu";
import useTheme from "../../../hooks/useTheme";
import Logo from "../../../assets/media/aden/logo-eseade-40.png";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

const Navegation = (props) => {
    const { toggleMenu, menu, closeMenu } = props;

    // REDUX
    const { secondary } = useTheme();

    // RETURN
    return (
        <>
            <Header color={secondary}>
                <LogoWrapper>
                    <FakeIconButton
                        onClick={toggleMenu}
                        data-tut="reactour__inicio_mobile"
                    >
                        <MenuRounded color="secondary" />
                    </FakeIconButton>
                    <Link to="/campus">
                        <FakeImg loading="lazy" src={Logo} alt="logo" />
                    </Link>
                </LogoWrapper>
                <Nav>
                    <ButtonCanvas
                        rel="noopener noreferrer"
                        target={"_blank"}
                        href="https://aden.instructure.com/login/openid_connect"
                    >
                        <ArrowForwardIcon />
                        <p>Ir a CANVAS</p>
                    </ButtonCanvas>
                </Nav>
            </Header>
            <Menu menu={menu} closeMenu={closeMenu}></Menu>
        </>
    );
};

export default Navegation;

const Header = styled.header`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 24px;
    position: fixed;
    right: 0;
    width: calc(100% - 115px);
    height: 70px;
    background-color: ${(p) => p.color};
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    z-index: 999;

    @media (max-width: 768px) {
        width: calc(100% - 30px);
    }
`;

const LogoWrapper = styled.div`
    display: flex;
    align-items: center;
    column-gap: 1rem;

    @media (max-width: 425px) {
        column-gap: 0.5rem;
    }
`;

const FakeIconButton = styled(IconButton)`
    display: none;

    @media (max-width: 768px) {
        display: flex;
    }
`;

const FakeImg = styled.img`
    max-height: 46px;
`;

const Nav = styled.nav`
    display: flex;
    align-items: center;
    column-gap: 1rem;

    @media (max-width: 425px) {
        column-gap: 0.5rem;
    }
`;

const ButtonCanvas = styled.a`
    width: 100%;
    border-radius: 30px;
    display: flex;
    align-items: center;
    cursor: pointer;
    justify-content: center;
    gap: 0.5rem;
    padding: 0.8rem 1rem;
    background: linear-gradient(
        34deg,
        rgba(78, 132, 249, 1) 0%,
        rgba(78, 132, 249, 0.8) 50%,
        rgba(78, 132, 249, 0.4) 100%
    );

    svg {
        color: #fff;
    }

    p {
        color: #fff;
    }
`;
