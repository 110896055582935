import * as types from "../types";
import { call, put, takeEvery } from "redux-saga/effects";
import { authActions } from "../actions";
import { loginUser } from "../api/auth";

function* loginRequest(action) {
    try {
        const res = yield call(loginUser, action.payload);
        yield put(
            authActions.loginUserSuccess({
                user: { ...res.data.response_data, data_login: action.payload },
                origin: action.payload.origin,
            })
        );
    } catch (error) {
        yield put(authActions.loginUserFail(error));
    }
}

function* authWatcher() {
    yield takeEvery(types.LOGIN_USER_REQUEST, loginRequest);
}

export { authWatcher };
