import * as types from '../types';
// import * as authTypes from '../types/auth_types';

const actions = {};

// LOGIN
actions.loginUserRequest = (data) => {
    return {
        type: types.LOGIN_USER_REQUEST,
        payload: data
    };
};

actions.loginUserSuccess = (payload) => {
    return {
        type: types.LOGIN_USER_SUCCESS,
        payload: payload.user
    };
};

actions.loginUserFail = (error) => {
    return {
        type: types.LOGIN_USER_FAIL,
        payload: error
    };
};

actions.clearErrorLogin = () => {
    return {
        type: types.CLEAR_ERROR_LOGIN
    };
};

// LOGOUT
actions.logoutUser = () => {
    return {
        type: types.LOGOUT_USER
    };
};



export { actions };
