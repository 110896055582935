import React from "react";
import styled from "styled-components";
import { Img } from "../../common/Image";
import Logo from "../../../assets/media/aden/logo-eseade-40.png";
import Text from "../../common/Text";
import AuthComponent from "../../ui/organisms/auth/AuthComponent";

const AuthForm = () => {
    return (
        <AuthFormWrapper>
            <Img w="185px" src={Logo} />
            <AuthComponent />
            <TextFake
                onClick={() => window.open("https://www.eseade.edu.ar/")}
                fontWeight="500"
                textColor="#4e84f9"
                fontSize="1rem"
            >
                www.eseade.edu.ar
            </TextFake>
        </AuthFormWrapper>
    );
};

export default AuthForm;

const AuthFormWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 4rem;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
`;

const TextFake = styled(Text)`
    cursor: pointer;

    @media (max-width: 768px) {
        position: fixed;
        bottom: 1rem;
    }
`;
