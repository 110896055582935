import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";

// COMPONENTS
// import LoginPage from "./pages/auth/LoginPage";
import Campus from "./pages/home/Campus";

// ROUTES
import PublicRoute from "./routes/PublicRoute.js";
import NotFound from "./pages/error/NotFound";
import RedirectTo from "./pages/RedirectTo";
import AuthPage from "./pages/auth/AuthPage";
import CommonRoute from "./routes/CommonRoute";
import ErrorRoute from "./routes/ErrorRoute";
import Logout from "./pages/auth/Logout";
import Microlearnings from "./pages/microlearnings/Microlearnings";
import CurrentMicrolearning from "./pages/microlearnings/CurrentMicrolearning";

const Routes = () => {
    return (
        <Switch>
            {/* RUTAS PUBLICAS ----------------------------------------------------------------------- */}

            {/* AutoLogin */}
            {/* <AutoLoginRoute path="/autologin" exact /> */}

            {/* Auth */}
            <PublicRoute
                layout="common"
                path="/auth"
                exact
                component={AuthPage}
            />
            {/* <PublicRoute
                layout="common"
                path="/auth/login"
                exact
                component={LoginPage}
            /> */}

            {/* Redirect default */}
            <Route path="/redirect" exact component={RedirectTo} />

            {/** RUTAS PRIVADAS -------------------------------------------------------------------------*/}

            {/* Auth */}
            <CommonRoute
                layout="common"
                path="/logout"
                exact
                component={Logout}
                blockedRoles={[
                    "alumno",
                    "docente",
                    "academico",
                    "visita",
                    "ondemand",
                ]}
            />

            {/* Usuario en comun */}
            <CommonRoute
                layout="common"
                path="/campus"
                exact
                component={Campus}
                blockedRoles={["docente", "academico"]}
                permissionRoles={["ondemand", "alumno", "visita"]}
            />

            <CommonRoute
                layout="common"
                path="/microlearnings"
                exact
                component={Microlearnings}
                blockedRoles={["docente", "academico"]}
                permissionRoles={["ondemand", "alumno", "visita"]}
            />

            <CommonRoute
                layout="common"
                path="/microlearnings/:id"
                exact
                component={CurrentMicrolearning}
                blockedRoles={["docente", "academico"]}
                permissionRoles={["ondemand", "alumno", "visita"]}
            />

            <ErrorRoute
                layout="common"
                path="/not-found"
                exact
                component={NotFound}
            />
            {/* Redirect default */}
            <Redirect path="*" to="/not-found" />
        </Switch>
    );
};

export default React.memo(Routes);
