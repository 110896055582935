import React, { useState, useEffect } from "react";
import Text from "../../../common/Text";
import Button from "@mui/material/Button";
import styled from "styled-components";
import { CircularProgress, IconButton, InputAdornment } from "@mui/material";
import { Form, Formik } from "formik";
import { loginSchema } from "../../../../utils/schemas";
import DefaultField from "../../../common/DefaultField";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { authActions } from "../../../../redux/actions";
import { useSnackbar } from "react-simple-snackbar";
import { optionsStyle } from "../../../../utils/snackStyles";

const LoginForm = () => {
    // FORMIK
    const initialValues = {
        user: "",
        password: "",
    };

    // STATE
    const [showPassword, setShowPassword] = useState(false);

    // REDUX
    const dispatch = useDispatch();
    const [openSnackbar] = useSnackbar(optionsStyle);
    const { loadingLogin, error } = useSelector((state) => state.auth);

    useEffect(() => {
        if (error) {
            openSnackbar("¡Ocurrió un error al acceder con este usuario!");
        }

        // eslint-disable-next-line
    }, [error]);

    // useEffect(() => {
    //     if (!!token) {
    //         // aca debería redirigir al campus
    //     }
    // }, [token]);

    // FUNCTIONS
    const toggleShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const handleSubmit = (values) => {
        dispatch(authActions.loginUserRequest(values));
    };

    return (
        <Wrapper>
            <Formik
                initialValues={initialValues}
                onSubmit={handleSubmit}
                validationSchema={loginSchema}
            >
                {() => (
                    <FormContainer>
                        <TextFieldFake
                            name="user"
                            type="email"
                            placeholder="Ingresa tu correo electrónico"
                            required
                        />
                        {/** Password */}
                        <TextFieldFake
                            name="password"
                            type={showPassword ? "text" : "password"}
                            required
                            label="Contraseña"
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            size="small"
                                            onClick={toggleShowPassword}
                                        >
                                            {showPassword ? (
                                                <Visibility fontSize="small" />
                                            ) : (
                                                <VisibilityOff fontSize="small" />
                                            )}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />

                        <ButtonWrapper>
                            {!loadingLogin && (
                                <Button
                                    variant="contained"
                                    type="submit"
                                    color="primary"
                                >
                                    Ingresar
                                </Button>
                            )}
                            {loadingLogin && (
                                <Center>
                                    <CircularProgress
                                        size="1.3rem"
                                        color="secondary"
                                    />{" "}
                                    <Text
                                        fontSize="1rem"
                                        fontWeight="600"
                                        textColor="#bfbfbf"
                                    >
                                        Ingresando
                                    </Text>
                                </Center>
                            )}
                            <Button
                                onClick={() =>
                                    window.open(
                                        "https://fa.eseade.edu.ar/password/forgot?tenantId=d3bd84c3-e6ce-0c04-5015-c24789968d57&client_id=3c219e58-ed0e-4b18-ad48-f4f92793ae32&nonce=&pendingIdPLinkId=&redirect_uri=%2Fadmin%2Flogin&response_mode=&response_type=code&scope=offline_access&state=It-BMSvXEhs-FMTPgrQtMvBi1qVoAve6pg9aq3x5zfM&timezone=America%2FBuenos_Aires&metaData.device.name=Windows%20Chrome&metaData.device.type=BROWSER&code_challenge=Y45Yoz-pqZ9j0gPCWsKuCBLVfnG2WBx6Efa9GH0JNoc&code_challenge_method=S256&user_code="
                                    )
                                }
                            >
                                Olvidé mi contraseña
                            </Button>
                        </ButtonWrapper>
                    </FormContainer>
                )}
            </Formik>
        </Wrapper>
    );
};

export default LoginForm;

const TextFieldFake = styled(DefaultField)`
    max-width: 100%;

    @media screen and (max-width: 768px) {
        max-width: 100%;
        width: 100%;
    }
`;

const ButtonWrapper = styled.div`
    margin: 1rem 0;
    display: flex;
    flex-direction: column;
    gap: 1rem;
`;

const FormContainer = styled(Form)`
    display: flex;
    flex-direction: column;
    gap: 1rem;
`;

const Center = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
`;

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 45%;
`;
